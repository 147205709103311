<template>
  <div>
    <div
      class="cupertino-container-common"
      :class="containerClass"
    >
      <div
        ref="pane"
        class="target-panel scroll-customize"
        :class="[targetClass, propTargetClass].join(' ')"
      >
        <keep-alive>
          <component :is="entryComponent"></component>
        </keep-alive>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Ref } from 'vue';
import { onMounted, ref, watch } from 'vue';
import type { CupertinoSettings } from 'cupertino-pane';
import { CupertinoPane } from 'cupertino-pane';

const CUPERTINODEFAULTOPTS: CupertinoSettings = {
  cssClass: 'cupertino-custom',
  dragBy: ['.cupertino-container-common .pane .draggable'],
  simulateTouch: true,
  fastSwipeSensivity: 5,
  showDraggable: true,
  fitHeight: true,
  backdrop: true,
  fastSwipeClose: true,
  ionContentScroll: true,
  topperOverflow: true
};

const {
  drawerOptions = {},
  id = '',
  propTargetClass = '',
  entryComponent = null,
  entryAnimation = true,
  isShow = false
} = defineProps<{
	drawerOptions?: CupertinoSettings;
	entryAnimation?: boolean;
	entryComponent?: unknown;
	isShow?: boolean;
	id?: string | number;
	propTargetClass?: string;
}>();

const emit = defineEmits<{
	didDismiss: [cupertino: CupertinoPane | null];
	willDismiss: [];
	didPresent: [cupertino: CupertinoPane | null];
	willPresent: [cupertino: CupertinoPane | null];
	dragStart: [number];
	drag: [number];
	dragEnd: [];
	backdropTap: [];
	transitionStart: [];
	transitionEnd: [];
}>();

const cupertino: Ref<CupertinoPane | null> = ref(null);
const pane: Ref<HTMLDivElement> = ref(document.createElement('div'));
const containerClass = `cupertino-container${id ? `-${id}` : id}`;
const targetClass = `cupertino-pane${id ? `-${id}` : id}`;
// const targetClass = [`cupertino-pane${id ? `-${id}` : id}`, propTargetClass].filter((t: string) => !!t).join(' ');
const isPaneWasDraggableDown = ref<boolean>(false);

const staticOpts = {
  events: {
    onDidDismiss: () => emit('didDismiss', cupertino.value),
    onWillDismiss: () => onWillDismiss(),
    onDidPresent: () => emit('didPresent', cupertino.value),
    onWillPresent: () => onWillPresent(),
    onDragStart: () => emit('dragStart', Math.round(pane.value.getBoundingClientRect().y)),
    onDrag: () => emit('drag', Math.round(pane.value.getBoundingClientRect().y)),
    onDragEnd: () => onDragEnd(),
    onBackdropTap: () => closeModal(),
    onTransitionStart: () => emit('transitionStart'),
    onTransitionEnd: () => emit('transitionEnd')
  }
};

const initCupertino = (options: CupertinoSettings): CupertinoPane => {
  cupertino.value = new CupertinoPane(`.${targetClass}`, {
    parentElement: `.${containerClass}`,
    ...options
  }) as CupertinoPane;

  if (isShow) {
    cupertino.value?.present({ animate: entryAnimation });
  }

  return cupertino.value;
};
const onWillPresent = () => {
  document.body.style.overflow = 'hidden';
  emit('willPresent', cupertino.value);
};

const onWillDismiss = () => {
  document.body.style.overflow = '';
  emit('willDismiss');
};

const onDragEnd = () => {
  isPaneWasDraggableDown.value = true;
  emit('dragEnd');
};

const closeModal = async () => {
  if (cupertino.value) {
    const animate = !isPaneWasDraggableDown.value;
    await cupertino.value.destroy({ animate });
    emit('backdropTap');
  }
};

onMounted(() => {
  initCupertino({ ...CUPERTINODEFAULTOPTS, ...drawerOptions, ...staticOpts });
});

watch(
  () => drawerOptions,
  () => {
    (cupertino.value as CupertinoPane).destroy({
      animate: entryAnimation
    });
    setTimeout(
      () => initCupertino({ ...CUPERTINODEFAULTOPTS, ...drawerOptions, ...staticOpts }),
      1000
    );
  }
);

watch(
  () => isShow,
  () => {
    isShow
      ? (cupertino.value as CupertinoPane).present({ animate: entryAnimation })
      : (cupertino.value as CupertinoPane).hide();
  }
);

</script>
